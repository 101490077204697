<template>
    <div style="position:relative;"  class="concrete">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <div>
                    <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Automating <span style="color:#00c8d8;"> Concrete Construction Business with Digital Docketing App</span></h1>
                    <p>                
                    Are you in the concreting industry and looking for an all-in-one software solution to organise your concrete work on the <a href="https://recordtime.com.au/blog/jobsite-manager">jobsite</a>? Look no further! Record Time is here to revolutionise how you manage your concrete construction services. 
                    <br><br>
                    Record Time offers users flexibility and accessibility on the go through its availability as both a mobile app and a website. Not only can concrete contractors can access all the project information in a single dashboard, but also make updates and remain in touch with their team from any device.

                    <br><br><br>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                        @click="redirectToLink('https://recordtime.com.au/blog/best-construction-scheduling-software')" class="mr-7 btn-1" elevation="0">
                        Read More
                    </v-btn>
                    <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                        Get A Demo Now
                    </v-btn>
                    </p>
                </div>
            </div>
            <div  class="right-banner" style="text-align: right;">
                <img :src="`${publicPath}assets/whoweserve/concreate-business.webp`" alt=" Record TIME in concrete construction management work"  style="max-width: 90%;">
            </div>
            <div style="clear: both;"></div>
            <br><br>
                    <h2>Improve Productivity, Efficiency, and Communication </h2>
                    <p>Concreting companies often have numerous subcontractors. Record TIME assists in overseeing these subcontractors by monitoring their performance and ensuring their alignment with project objectives and deadlines. As a consequence, it fosters effective teamwork and accountability. Furthermore, Record TIME eliminates the need for manual and repetitive tasks, reducing human errors and enhancing overall efficiency and productivity.</p>
        </div>
        <v-container>
            <div class="gray-container">
                <h3>Experience the Seamless<span> Concrete Contracting</span></h3>
                <img :src="`${publicPath}assets/whoweserve/seamless-concrete-contracting.webp`" alt="experience the seamless concrete contracting for better productivity" style="margin-top: 20px; object-fit: contain;width: 100%;">
                </div>
             <br><br>
            
                <h2>The Unified Docketing App for Concreteing Companies</h2><br/>
                <p>Record TIME offers contractors a range of features that enhance the efficiency of planning and managing concrete work timelines, resource allocation, and task coordination. One special function is the ability for team members to create pre start checklists to ensure safety measures. Additionally, contractors can take advantage of the <a href="https://recordtime.com.au/proof-of-work-completion"> proof-of-work</a> feature, which allows them to record completed tasks. In addition, users can also capture photos, and document any issues that arise during the execution of the concrete work.
                <br><br>
                Within the concrete industry, prioritising safety is of utmost importance, and effective incident reporting plays a vital role in maintaining a secure work environment. Therefore, Record TIME facilitates the creation of digital incident reports, enabling users to swiftly report and document any accidents or injuries. By streamlining the reporting process, contractors can ensure that incidents are promptly addressed, leading to faster responses and maintenance. Another valuable aspect of Record TIME is its digital docketing approach, which provides a centralised platform for organising, storing, and accessing critical project documents. Overall, with Record TIME contractors can easily assign tasks to employees, monitor their progress, track equipment, and communicate instantly resulting in the enhancement of overall project management efficiency.
                </p>

                <img :src="`${publicPath}assets/whoweserve/concrete-construction-software.webp`" style="max-width: 100%;" alt="schedule jobs, plants and employee in your concrete construction site with Record TIME">

            <div style="clear:both;"></div>
            
        </v-container>
        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>
</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 45%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width:55%; 
        float: right;
        @media only screen and (max-width: 1280px) {
            width: 100%;
            padding: 0px;
            img{
                max-width:100% !important;
            }
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .concrete{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:right;
      max-width: calc(100% - 700px);
      padding-right: 15px;
    }
    .right-blog1{
      width: 700px;
      float:right;
      img{
        max-width: 600px;
        width: 100%;
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';
export default{
    metaInfo: {
    title: 'Digital Docketing App For Concreting Business',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Digitalise your concrete business with a digital docketing app. Paperless documentation, easy scheduling, automated timesheets, safety measures, and reporting.'},
      {name: 'keyword', content: 'digital docketing app for concreting business'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/digital-docketing-app-for-concreting' }
    
    ]
  
  },
  components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>